





















import { defineComponent, ref } from '@vue/composition-api'
import { pushCta } from '@/inc/utils'

export default defineComponent({
  name: 'HeroPromo',
  components: {},
  props: {
    label: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const rootEl = ref<HTMLElement | null>(null)

    const onClick = (anchor: string) => {
      // Scroll to block
      const el = document.body.querySelector(`#${anchor}`)

      if (el) {
        const { top } = el.getBoundingClientRect()
        const headerHeight =
          document.querySelector('.header')?.clientHeight || 0

        window.scrollTo({
          top: top - headerHeight + window.scrollY,
          left: 0,
          behavior: 'smooth',
        })
      }

      // Track click
      if (rootEl.value) {
        pushCta(
          {
            ctaLabel: props.label,
            ctaType: 'scroll_promo',
          },
          rootEl.value
        )
      }
    }

    return {
      rootEl,
      onClick,
    }
  },
})
